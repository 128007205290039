import { FC, useCallback, useMemo, useState } from 'react';
import { ModuleDefaultConfiguration } from '../../models/ClientModuleDefaults';
import { AccessTypeKeys } from '../../models/Access';
import AccessDropdown from '../shared/AccessDropdown';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { ClientFormUser } from '../../models/ClientFormUser';
import AccessControl from '../ownership/AccessControl';
import User from '../../models/User';
import UserInvitation from '../../models/UserInvitation';
import { useManageDocumentClassesWizard } from '../../contexts/ManageDocumentClassesContext';
import { useCreateDocumentWizard } from '../../contexts/CreateDocumentContext';

type CreateAccessProps = {
  defaults: ModuleDefaultConfiguration;
  onDefaultsChange: (defaults: ModuleDefaultConfiguration) => void;
  clientUsers: User[];
  requireValidator?: boolean;
  requireApprover?: boolean;
  requireOwner?: boolean;
  inviteUserMode?: boolean;
  onUserInviteChange?: (user: UserInvitation) => void;
  onUserInviteValid?: (isValid: boolean) => void;
  modalIsValid: boolean;
};

const ManageAccess: FC<CreateAccessProps> = (props) => {
  const {
    defaults,
    onDefaultsChange,
    clientUsers,
    requireValidator,
    requireApprover,
    requireOwner,
    inviteUserMode,
    onUserInviteChange,
    onUserInviteValid,
    modalIsValid,
  } = props;
  const { t } = useTranslation(['documents']);
  const currentClient = useRecoilValue(currentClientAtom);
  const { selectedClass, setSelectedClass, newTemplate, setTemplate } = useManageDocumentClassesWizard() ?? {};
  const { newDocument, classes } = useCreateDocumentWizard() ?? {};

  const configValueChange = useCallback(
    <TKey extends keyof ModuleDefaultConfiguration>(key: TKey, value: ModuleDefaultConfiguration[TKey]) => {
      onDefaultsChange({ ...defaults, [key]: value });
    },
    [defaults, onDefaultsChange],
  );

  const assignedUsers = useMemo((): ClientFormUser[] => {
    if (defaults && clientUsers.length) {
      const users = (defaults?.users || [])
        .map((x) => {
          const clientUser = clientUsers.find((user) => user.id && user.id === x.userId)!;
          if (clientUser) return { ...clientUser, ...x };

          return null;
        })
        .filter(Boolean);

      return users;
    }
    return [];
  }, [clientUsers, defaults]);

  const onUsersChange = useCallback(
    (users: ClientFormUser[]) => {
      configValueChange(
        'users',
        users.map((x) => ({
          userId: x.id,
          role: x.role,
          requiresAction: x.requiresAction ?? undefined,
          sortOrder: x.sortOrder ?? undefined,
        })),
      );
    },
    [configValueChange],
  );

  const [createDocumentOwnerRequiresAction, setCreateDocumentOwnerRequiresAction] = useState(
    classes?.find((x) => x.clientModuleId === newDocument?.clientModuleId)?.templateModule?.requiresOwnerAcknowledgement,
  );

  return (
    <>
      {!inviteUserMode && (
        <>
          <div className="relative py-4">
            <AccessDropdown selectedAccess={defaults.accessType} onChange={(value) => configValueChange('accessType', value)} />
            <div className="pl-6">{t(AccessTypeKeys[defaults.accessType], { client: currentClient?.name })}</div>
          </div>
          <div className="text-dpm-16 pb-4 font-medium">{t('access.default-members')}</div>
        </>
      )}
      <AccessControl
        users={assignedUsers}
        onUsersChange={onUsersChange}
        requireValidator={requireValidator}
        requireApprover={requireApprover}
        requireOwner={requireOwner}
        inviteMode={inviteUserMode}
        onUserInviteChange={onUserInviteChange}
        onUserInviteValid={onUserInviteValid}
        modalIsValid={modalIsValid}
        ownerRequiresAction={
          // Creating a new document
          createDocumentOwnerRequiresAction ||
          // Creating a new class
          newTemplate?.requiresOwnerAcknowledgement ||
          // Modifying an existing class
          selectedClass?.templateModule.requiresOwnerAcknowledgement
        }
        onOwnerRequiresActionChange={(value) => {
          setTemplate?.((prev) => prev && { ...prev, requiresOwnerAcknowledgement: value });
          setSelectedClass?.((prev) => prev && { ...prev, templateModule: { ...prev.templateModule, requiresOwnerAcknowledgement: value } });
          setCreateDocumentOwnerRequiresAction(value);
        }}
      />
    </>
  );
};

export default ManageAccess;
