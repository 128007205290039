import { createContext, useContext } from 'react';
import User from '../models/User';
import { DocumentRequest } from '../models/Document';
import { CreateDocumentWizardStep } from '../components/documents/create-document/CreateDocumentWizard';
import { ClientModuleTemplateDefault } from '../models/ClientModuleDefaults';
import { DocumentReferenceAnswer } from '../components/form/action-types/document-reference/DocumentReferenceAction';

type CreateDocumentContext = {
  classes: ClientModuleTemplateDefault[];
  newDocument: DocumentRequest;
  setNewDocument?: (value: DocumentRequest | ((prev: DocumentRequest) => DocumentRequest)) => void;
  cancel?: () => void;
  nextStep?: (stepName: CreateDocumentWizardStep) => void;
  prevStep?: () => void;
  stepNames?: readonly CreateDocumentWizardStep[];
  clientUsers: User[];
  onCreated: (id: string, classId: string, nextDocuNumber: number) => void;
  reset: () => void;
  selectedDocuments?: DocumentReferenceAnswer[];
  disabledDocments?: DocumentReferenceAnswer[];
  onReference?: (references: DocumentReferenceAnswer[]) => void;
  onUserInvited: () => void;
};

export const CreateDocumentContext = createContext<CreateDocumentContext>(null!);
export const useCreateDocumentWizard = (): CreateDocumentContext => useContext(CreateDocumentContext);
